<template>
  <Geral />
</template>

<script>
import Geral from "@/components/gestao/Geral.vue";

export default {
  components: {
    Geral
  }
};
</script>
